<template>
  <div>
    <div
      class="profile-cover"
      style="height: 255px"
    >
      <div class="row">
        <div
          class="col-md-3 profile-image"
          style="margin-top: 175px"
        >
          <div class="profile-image-container">
            <img
              :src="getProfilePicture"
              alt="profile"
              class="img-cover"
            >
          </div>
        </div>
        <!-- <div class="col-md-12 profile-info">
					<div class=" profile-info-value">
						<h3>1020</h3>
						<p>Followers</p>
					</div>
					<div class=" profile-info-value">
						<h3>1780</h3>
						<p>Friends</p>
					</div>
					<div class=" profile-info-value">
						<h3>260</h3>
						<p>Photos</p>
					</div>
				</div> -->
      </div>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-3 user-profile m-b-lg">
          <h3 class="text-center">
            {{ _getAttributes.userName }}
          </h3>
          <p class="text-center">
            {{ _getAttributes.jobTitle }}
          </p>
          <hr>
          <ul class="list-unstyled text-center">
            <li>
              <p>
                <i class="fas fa-map-marker m-r-xs" />
                <span>{{ _getAttributes.branchLocation }}</span>
              </p>
            </li>
            <li>
              <p>
                <i class="fas fa-box-open m-r-xs" />
                <span>{{ _getAttributes.products }}</span>
              </p>
            </li>
            <li>
              <p>
                <i class="fas fa-phone m-r-xs" />
                <span>{{ _getAttributes.phone }}</span>
                <button
                  v-if="!_getAttributes.phoneVerified && _getAttributes.phone !== '' && _getAttributes.phone !== 'No Mobile Number Provided'"
                  id="phoneVerify"
                  class="btn-link text-danger"
                  @click.stop.prevent="openVerifyModal"
                >
                  (VERIFY)
                </button>
              </p>
            </li>
            <li>
              <p>
                <i class="fas fa-envelope m-r-xs" />
                <a href="#">{{ _getAttributes.email }}</a>
                <button
                  v-if="!_getAttributes.emailVerified && _getAttributes.email !== '' && _getAttributes.email !== 'No Email Provided'"
                  id="emailVerify"
                  class="btn-link text-danger"
                  @click.stop.prevent="openVerifyModal"
                >
                  (VERIFY)
                </button>
              </p>
            </li>
            <li>
              <p>
                <i class="fas fa-link m-r-xs" />
                <a
                  :href="(_getAttributes.website !== '' && _getAttributes.website !== 'Link Not Created') ? _getAttributes.website : '#'"
                  target="_blank"
                >
                  {{ _getAttributes.website }}
                </a>
              </p>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="panel panel-white">
            <div
              class="panel-body"
              style="padding-bottom: 0"
            >
              <div role="tabpanel">
                <!-- Nav tabs -->
                <ul
                  class="nav nav-tabs"
                  role="tablist"
                >
                  <li
                    class="active"
                    role="presentation"
                  >
                    <a
                      data-toggle="tab"
                      href="#tab1"
                      role="tab"
                    >Profile</a>
                  </li>
                  <li role="presentation">
                    <a
                      data-toggle="tab"
                      href="#tab2"
                      role="tab"
                    >Change
                      Password</a>
                  </li>
                </ul>
                <!-- Tab panes -->
                <div class="tab-content">
                  <div
                    id="tab1"
                    class="tab-pane active fade in"
                    role="tabpanel"
                  >
                    <form
                      id="profile"
                      class="post"
                    >
                      <div class="row">
                        <div class="col-lg-6">
                          <label class="has-float-label">
                            <input
                              v-model="updateDetails.preferredUsername"
                              placeholder=" "
                              type="text"
                            >
                            <span class="float-label">Preferred Username (case sensitive)</span>
                          </label>
                        </div>
                        <div class="col-lg-6">
                          <label class="has-float-label">
                            <input
                              v-model="updateDetails.nickname"
                              placeholder=" "
                              type="text"
                            >
                            <span class="float-label">Nickname</span>
                          </label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <label class="has-float-label">
                            <input
                              v-model="updateDetails.firstName"
                              v-validate="'required'"
                              :class="errors.has('updateDetails.firstName') ? 'error' : ''"
                              data-vv-as="first name"
                              data-vv-scope="updateDetails"
                              name="updateDetails.firstName"
                              placeholder=" "
                              type="text"
                            >
                            <span class="float-label">First Name</span>
                            <span
                              v-show="errors.has('updateDetails.firstName')"
                              class="help text-danger"
                            >{{ errors.first('updateDetails.firstName') }}</span>
                          </label>
                        </div>
                        <div class="col-lg-6">
                          <label class="has-float-label">
                            <input
                              v-model="updateDetails.lastName"
                              v-validate="'required'"
                              :class="errors.has('updateDetails.lastName') ? 'error' : ''"
                              data-vv-as="last name"
                              data-vv-scope="updateDetails"
                              name="updateDetails.lastName"
                              placeholder=" "
                              type="text"
                            >
                            <span class="float-label">Last Name</span>
                            <span
                              v-show="errors.has('updateDetails.lastName')"
                              class="help text-danger"
                            >{{ errors.first('updateDetails.lastName') }}</span>
                          </label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <label class="has-float-label">
                            <input
                              v-model="updateDetails.birthDate"
                              v-validate="'required|date_format:dd-MM-yyyy'"
                              :class="errors.has('updateDetails.birthDate') ? 'error' : ''"
                              class="date-picker"
                              data-vv-as="DOB"
                              data-vv-scope="updateDetails"
                              name="updateDetails.birthDate"
                              placeholder=" "
                              type="text"
                            >
                            <span class="float-label">Date Of Birth</span>
                            <span
                              v-show="errors.has('updateDetails.birthDate')"
                              class="help text-danger"
                            >{{ errors.first('updateDetails.birthDate') }}</span>
                          </label>
                        </div>
                        <div class="col-lg-6">
                          <div class="non-float-input">
                            <label>Gender:</label>
                            <div class="radio-input-group">
                              <div class="radio-inline">
                                <label>
                                  <input
                                    v-model="updateDetails.gender"
                                    v-validate="'required'"
                                    :class="errors.has('updateDetails.gender') ? 'error' : ''"
                                    class="form-control"
                                    data-vv-as="gender"
                                    data-vv-scope="updateDetails"
                                    name="updateDetails.gender"
                                    type="radio"
                                    value="Male"
                                  >Male
                                </label>
                              </div>
                              <div class="radio-inline">
                                <label>
                                  <input
                                    v-model="updateDetails.gender"
                                    class="form-control"
                                    data-vv-scope="updateDetails"
                                    name="updateDetails.gender"
                                    type="radio"
                                    value="Female"
                                  >Female
                                </label>
                              </div>
                            </div>
                          </div>
                          <span
                            v-show="errors.has('updateDetails.gender')"
                            class="help text-danger"
                          >{{ errors.first('updateDetails.gender') }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <label class="has-float-label">
                            <input
                              v-model="updateDetails.email"
                              v-validate="'required|email'"
                              :class="errors.has('updateDetails.email') ? 'error' : ''"
                              data-vv-as="email"
                              data-vv-scope="updateDetails"
                              name="updateDetails.email"
                              placeholder=" "
                              type="text"
                            >
                            <span class="float-label">Email Address</span>
                            <span
                              v-show="errors.has('updateDetails.email')"
                              class="help text-danger"
                            >{{ errors.first('updateDetails.email') }}</span>
                          </label>
                        </div>
                        <div class="col-lg-6">
                          <label class="has-float-label">
                            <input
                              v-model="updateDetails.phone"
                              v-validate="{ required: true, regex: /^\+91[\d]{10}$/ }"
                              :class="errors.has('updateDetails.phoneNumber') ? 'error' : ''"
                              data-vv-as="phone number"
                              data-vv-scope="updateDetails"
                              name="updateDetails.phoneNumber"
                              placeholder=" "
                              type="text"
                            >
                            <span class="float-label">Mobile Number</span>
                            <span
                              v-show="errors.has('updateDetails.phoneNumber')"
                              class="help text-danger"
                            >{{ errors.first('updateDetails.phoneNumber') }}</span>
                          </label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="non-float-static">
                            <label>Profile picture: </label>
                            <div
                              id="profilePicture"
                              class="dropzone"
                            >
                              <!-- Not displayed, just for Dropzone's `dictDefaultMessage` option -->
                              <div
                                id="dropzone-message"
                                style="display: none"
                              >
                                <span class="dropzone-title">Drop your file here or click to select</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="non-float-static">
                            <label class="m-r-md">Customer Link: </label>
                            <a
                              v-if="updateDetails.website"
                              :href="(updateDetails.website !== '' && updateDetails.website !== 'Link Not Created') ? updateDetails.website : '#'"
                              class="text-muted form-control-static"
                              target="_blank"
                            >{{
                              updateDetails.website
                            }}</a>
                            <button
                              v-else
                              class="btn btn-success btn-addon m-b-sm"
                              type="button"
                              @click.stop.prevent="generateUniqueLink"
                            >
                              <i class="fas fa-link" />
                              Get Your Unique Link
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="post-options"
                        style="margin-top: 0"
                      >
                        <button
                          id="updateProfile"
                          class="btn btn-default pull-right"
                          @click.stop.prevent="updateProfile"
                        >
                          Update Profile
                        </button>
                      </div>
                    </form>
                  </div>
                  <div
                    id="tab2"
                    class="tab-pane fade"
                    role="tabpanel"
                  >
                    <div class="row">
                      <div class="col-lg-6">
                        <label class="has-float-label">
                          <input
                            v-model="passwordDetails.oldPassword"
                            v-validate="'required'"
                            :class="errors.has('passwordDetails.oldPassword') ? 'error' : ''"
                            data-vv-as="old password"
                            data-vv-scope="passwordDetails"
                            name="passwordDetails.oldPassword"
                            placeholder=" "
                            type="password"
                          >
                          <span class="float-label">Old Password</span>
                          <span
                            v-show="errors.has('passwordDetails.oldPassword')"
                            class="help text-danger"
                          >{{ errors.first('passwordDetails.oldPassword') }}</span>
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <label class="has-float-label">
                          <input
                            ref="passwordDetails.password"
                            v-model="passwordDetails.newPassword"
                            v-validate="'required'"
                            :class="errors.has('passwordDetails.password') ? 'error' : ''"
                            data-vv-as="password"
                            data-vv-scope="passwordDetails"
                            name="passwordDetails.password"
                            placeholder=" "
                            type="password"
                          >
                          <span class="float-label">New Password</span>
                          <span
                            v-show="errors.has('passwordDetails.password')"
                            class="help text-danger"
                          >{{ errors.first('passwordDetails.password') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-6">
                        <label class="has-float-label">
                          <input
                            v-model="passwordDetails.confirmPassword"
                            v-validate="'required|confirmed:passwordDetails.password'"
                            :class="errors.has('passwordDetails.confirmPassword') ? 'error' : ''"
                            data-vv-as="password"
                            data-vv-scope="passwordDetails"
                            name="passwordDetails.confirmPassword"
                            placeholder=" "
                            type="password"
                          >
                          <span class="float-label">Confirm New Password</span>
                          <span
                            v-show="errors.has('passwordDetails.confirmPassword')"
                            class="help text-danger"
                          >{{ errors.first('passwordDetails.confirmPassword') }}</span>
                        </label>
                      </div>
                    </div>
                    <div class="post-options">
                      <button
                        class="btn btn-default pull-right"
                        @click.stop.prevent="updatePassword"
                      >
                        Update Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="getTeamMembers.supervisor && getTeamMembers.supervisor !== ''"
          class="col-md-3"
        >
          <div class="panel panel-white">
            <div class="panel-heading">
              <div class="panel-title">
                {{ getTeamMembers.supervisor + '\'s' + ' Team' }}
              </div>
            </div>
            <div class="panel-body">
              <div class="team">
                <div
                  v-for="(member, index) in getTeamMembers.teamMembers"
                  :key="index"
                  class="team-member"
                >
                  <!--<p>{{ member.name }}</p>-->
                  <img
                    :id="'profile-' + index"
                    :alt="member.name"
                    :src="member.picture"
                    :title="member.name"
                    class="img-cover"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="verificationModal"
        aria-hidden="true"
        class="modal fade"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span
                  aria-hidden="true"
                >&times;</span>
              </button>
              <h4 class="modal-title text-center">
                Verification
              </h4>
            </div>
            <div class="modal-body">
              <form
                id="emailVerificationForm"
                class="row"
              >
                <div class="col-sm-12">
                  <label class="has-float-label">
                    <input
                      id="verificationCode"
                      v-model="verificationCode"
                      placeholder=" "
                      type="text"
                    >
                    <span class="float-label">Code</span>
                  </label>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-success"
                type="button"
                @click.stop.prevent="verifyAttribute"
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
    </div><!-- Main Wrapper -->
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import Dropzone from "dropzone";
import {API, Auth, graphqlOperation, Storage} from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";

export default {
  name: "Profile",
  data() {
    return {
      verificationCode: '',
      updateDetails: {
        preferredUsername: '',
        nickname: '',
        firstName: '',
        lastName: '',
        birthDate: '',
        gender: '',
        email: '',
        phone: '',
        website: '',
        picture: '',
        emailVerified: false,
        phoneVerified: false
      },
      passwordDetails: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      pictureUploader: null
    }
  },
  computed: {
    ...mapGetters([
      "getLogger",
      "getCognitoUser",
      "getLoggedInUser",
      "getTitles",
      "getBranches",
      "getUsers",
      "getProducts"
    ]),
    ...mapState([
      'loggedInUser'
    ]),
    _getAttributes() {
      let attributes = {
        userName: '',
        phone: 'No Mobile Number Provided',
        phoneVerified: false,
        email: 'No Email Provided',
        emailVerified: false,
        website: 'Link Not Created',
        jobTitle: 'No Job Title',
        branchLocation: 'Not Assigned To A Location',
        products: 'All Products',
        supervisor: null,
        teamMembers: []
      };
      if (this.getLoggedInUser && this.getLoggedInUser !== '') {
        attributes.phone = (this.getLoggedInUser.phone && this.getLoggedInUser.phone !== '') ?
            this.getLoggedInUser.phone : attributes.phone;

        attributes.phoneVerified = this.getLoggedInUser.phoneVerified ?
            this.getLoggedInUser.phoneVerified : false;

        attributes.email = (this.getLoggedInUser.email && this.getLoggedInUser.email !== '') ?
            this.getLoggedInUser.email : attributes.email;

        attributes.emailVerified = this.getLoggedInUser.emailVerified ?
            this.getLoggedInUser.emailVerified : false;

        attributes.website = (this.getLoggedInUser.website && this.getLoggedInUser.website !== '') ?
            this.getLoggedInUser.website : attributes.website;

        if (this.getLoggedInUser.jobTitle && this.getLoggedInUser.jobTitle !== '') {
          let jobTitle = _.find(this.getTitles, (title) => {
            return title.id === this.getLoggedInUser.jobTitle;
          });
          if (jobTitle) {
            attributes.jobTitle = jobTitle.name;
          }
        }

        if (this.getLoggedInUser.branchId && this.getLoggedInUser.branchId.length > 0) {
          let branches = '';
          _.forEach(this.getLoggedInUser.branchId, (branchId) => {
            let userBranch = _.find(this.getBranches, (branch) => {
              return branch.number === branchId;
            });
            if (userBranch && userBranch.name) {
              branches += userBranch.name + ', ';
            }
          });
          if (branches !== '') {
            branches = branches.substring(0, branches.length - 2);
          }
          attributes.branchLocation = branches;
        }

        if (this.getLoggedInUser.products && this.getLoggedInUser.products.length > 0) {
          let products = '';
          _.forEach(this.getLoggedInUser.products, (productId) => {
            let userProduct = _.find(this.getProducts, (product) => {
              return product.id === productId;
            });
            if (userProduct && userProduct.name) {
              products += userProduct.name + ', ';
            }
          });
          if (products !== '') {
            products = products.substring(0, products.length - 2);
          }
          attributes.products = products;
        }

        attributes.userName = (this.getLoggedInUser.firstName && this.getLoggedInUser.firstName !== '') ?
            _.capitalize(this.getLoggedInUser.firstName) : attributes.userName;
        attributes.userName = (this.getLoggedInUser.lastName && this.getLoggedInUser.lastName !== '') ?
            attributes.userName + ' ' + _.capitalize(this.getLoggedInUser.lastName) : attributes.userName;
      }
      return attributes;
    }
  },
  watch: {
    loggedInUser(newValue) {
      if (newValue && newValue !== '') {
        this.loadDetails(newValue);
      }
    },
    'updateDetails.phone'(newValue) {
      // TODO: Use new setting 'countryCode'
      if (newValue === '' || newValue === '+' || newValue === '+1' || newValue === '+9') {
        this.updateDetails.phone = '+91';
      } else if (!_.startsWith(newValue, "+91")) {
        if (_.startsWith(newValue, '+9') || _.startsWith(newValue, '+1')) {
          this.updateDetails.phone = '+91' + newValue.substring(2, newValue.length - 1);
        } else if (_.startsWith(newValue, '+')) {
          this.updateDetails.phone = '+91' + newValue.substring(1, newValue.length - 1);
        } else if (_.startsWith(newValue, '91')) {
          this.updateDetails.phone = '+' + newValue;
        } else {
          this.updateDetails.phone = '+91' + newValue;
        }
      }
    }
  },
  created() {
    Dropzone.autoDiscover = false;
  },
  async beforeMount() {
    let user = this.getCognitoUser;
    let credentials = await Auth.currentUserCredentials();
    if (user && user !== '' && user.attributes && user.attributes.sub && user.attributes.sub !== ''
        && credentials.identityId && credentials.identityId !== '') {
      const {
        data: {getUser}
      } = await API.graphql(graphqlOperation(queries.getUser, {id: user.attributes.sub}));
      if (!getUser) {
        const currentTimeEpoch = moment().format('X');
        try {
          let newUser = {
            id: user.attributes.sub,
            firstName: user.attributes.given_name,
            lastName: user.attributes.family_name,
            nickname: user.attributes.nickname,
            birthDate: user.attributes.birthdate,
            gender: user.attributes.gender,
            email: user.attributes.email,
            emailVerified: user.attributes.email_verified,
            phone: user.attributes.phone_number,
            phoneVerified: user.attributes.phone_number_verified,
            branchId: [user.attributes['custom:branch']],
            identityId: credentials.identityId,
            username: user.username,
            preferredUsername: user.attributes.preferred_username,
            picture: user.attributes.picture,
            website: user.attributes.website,
            isEnabled: true,
            lastOnline: moment(currentTimeEpoch, 'X').toISOString(),
            lastOnlineEpoch: currentTimeEpoch
          };

          await API.graphql(graphqlOperation(mutations.createUser, {
            input: _.omitBy(newUser, (field) => {
              return (!field) || field === '';
            })
          }));
          this.UPDATE_LOGGED_IN_USER(newUser);
        } catch (e) {
          this.setMessage('v-notify-error', "Error!", "You have entered incorrect information! Please re-enter your profile details.", 0);
        }
      }
    }
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');

    $('.date-picker').change((e) => {
      this.updateDetails.birthDate = $(e.target).val();
    });

    $(':radio').click((e) => {
      e.preventDefault();
      e.stopPropagation();

      _.set(this, e.currentTarget.name, e.currentTarget.value);
    });

    let options = {
      url: '/',
      method: 'put',
      maxFiles: 1,
      maxfilesexceeded: function (file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      acceptedFiles: "image/*",
      capture: 'file',
      addRemoveLinks: true,
      parallelUploads: 1,
      uploadMultiple: false,
      header: '',
      dictDefaultMessage: document.querySelector('#dropzone-message').innerHTML,
      autoProcessQueue: false
    };

    // Instantiate DropZone
    this.pictureUploader = new Dropzone("div#profilePicture", options);

    if (this.getLoggedInUser && this.getLoggedInUser !== '') {
      this.loadDetails(this.getLoggedInUser);
    }
  },
  asyncComputed: {
    getProfilePicture: {
      get() {
        if (this.getLoggedInUser && this.getLoggedInUser !== '' && this.getLoggedInUser.picture && this.getLoggedInUser.picture !== '') {
          return Storage.get(this.getLoggedInUser.picture, {
            level: 'protected'
          });
        } else {
          return require('../../assets/user.png');
        }
      },
      default() {
        return require('../../assets/user.png');
      }
    },
    getTeamMembers: {
      get() {
        let team = {
          supervisor: '',
          teamMembers: []
        };

        if (this.getLoggedInUser && this.getLoggedInUser !== '') {
          let jobTitle = _.find(this.getTitles, (title) => {
            return title.id === this.getLoggedInUser.jobTitle;
          });
          if (jobTitle) {
            let supervisor = this.getLoggedInUser;

            if (jobTitle.access === 'User') {
              supervisor = _.find(this.getUsers, (user) => {
                return user.id === this.getLoggedInUser.supervisor;
              });
            }

            if (supervisor) {
              let name = _.capitalize(supervisor.firstName);

              if (supervisor.lastName) {
                name += " " + _.capitalize(supervisor.lastName);
              }
              team.supervisor = name;

              let teamMembers = _.filter(this.getUsers, (user) => {
                return user.supervisor === supervisor.id;
              });
              if (teamMembers) {
                _.forEach(teamMembers, (member) => {
                  let memberDetails = {
                    name: _.capitalize(member.firstName),
                    picture: require('../../assets/user.png')
                  };

                  if (member.lastName) {
                    memberDetails.name += " " + _.capitalize(member.lastName);
                  }

                  if (member.picture && member.picture !== '') {
                    Storage.get(member.picture, {
                      level: 'protected',
                      identityId: member.identityId
                    }).then((url) => {
                      memberDetails.picture = url;
                    });
                  }

                  team.teamMembers.push(memberDetails);
                });
              }
            }
          }
        }
        return team;
      },
      default() {
        return {
          supervisor: '',
          teamMembers: []
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      "UPDATE_COGNITO_USER",
      "UPDATE_LOGGED_IN_USER"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    loadDetails: function (user) {
      this.updateDetails = {
        preferredUsername: user.preferredUsername || '',
        nickname: user.nickname || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        birthDate: user.birthDate || '',
        gender: user.gender || '',
        email: user.email || '',
        phone: user.phone || '',
        website: user.website || '',
        picture: user.picture || '',
        emailVerified: user.emailVerified || false,
        phoneVerified: user.phoneVerified || false
      };

      if (user.birthDate) {
        let dateChunks = user.birthDate.split('-');
        this.updateDetails.birthDate = dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0];
      }
    },
    async updateUserDetails() {
      let updateButton = $('#updateProfile');
      try {
        updateButton.attr('disabled', true);
        let files = this.pictureUploader.getQueuedFiles();
        if (files && files.length > 0) {
          let profilePicture = files[0];

          let uploadResult = await Storage.put(profilePicture.name, profilePicture, {
            level: 'protected',
            contentType: profilePicture.type
          });
          this.pictureUploader.removeAllFiles();
          this.updateDetails.picture = uploadResult.key;
        }

        let newDetails = _.cloneDeep(this.updateDetails);

        let dateChunks = newDetails.birthDate.split('-');
        newDetails.birthDate = dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0];

        if (this.getLoggedInUser && this.getLoggedInUser.id) {
          newDetails.id = this.getLoggedInUser.id;
          await API.graphql(graphqlOperation(mutations.updateUser, {
            input: _.omitBy(newDetails, (field) => {
              return (!field) || field === '';
            })
          }));
          newDetails.birthDate = dateChunks[0] + '-' + dateChunks[1] + '-' + dateChunks[2];
        } else {
          newDetails.id = this.getCognitoUser.attributes.sub;
          newDetails.emailVerified = false;
          newDetails.phoneVerified = false;
          newDetails.branchId = [this.getCognitoUser.attributes['custom:branch']];
          newDetails.products = [this.getCognitoUser.attributes['custom:products']];
          newDetails.username = this.getCognitoUser.username;
          newDetails.isEnabled = true;

          let credentials = await Auth.currentUserCredentials();
          newDetails.identityId = credentials.identityId;

          await API.graphql(graphqlOperation(mutations.createUser, {
            input: _.omitBy(newDetails, (field) => {
              return (!field) || field === '';
            })
          }));
          newDetails.birthDate = dateChunks[0] + '-' + dateChunks[1] + '-' + dateChunks[2];
          this.UPDATE_LOGGED_IN_USER(newDetails);
        }

        this.updateUser = {
          preferred_username: newDetails.preferredUsername,
          nickname: newDetails.nickname,
          given_name: newDetails.firstName,
          family_name: newDetails.lastName,
          email: newDetails.email,
          phone_number: newDetails.phone,
          website: newDetails.website
        };
        let result = await Auth.updateUserAttributes(this.getCognitoUser, this.updateUser);
        if (result === 'SUCCESS') {
          this.setMessage('v-notify-success', "Woohoo!", "Your profile was updated successfully!");
          await this.refreshUserAttributes();
          updateButton.attr('disabled', false);
        } else {
          this.setMessage('v-notify-error', "Sorry!", 'Unable to update profile at this time.');
          updateButton.attr('disabled', false);
        }
      } catch (e) {
        this.setMessage('v-notify-error', "Error!", e);
        updateButton.attr('disabled', false);
      }
    },
    updateProfile: function () {
      this.$validator.validate('updateDetails.*')
          .then((result) => {
            if (result) {
              this.updateUserDetails()
            } else {
              let firstErrorInput = $('input.error:first')[0];
              if (firstErrorInput) {
                firstErrorInput.scrollIntoView({behavior: 'instant'});
                firstErrorInput.focus();
              }
              if (this.errors.items && this.errors.items.length > 1) {
                this.setMessage('v-notify-error', "Oops!", "Please correct the " + this.errors.items.length + " errors on the form.");
              } else {
                let errorInputName = firstErrorInput.dataset.vvAs || firstErrorInput.name || 'form';
                if (errorInputName && errorInputName !== '') {
                  errorInputName = _.upperCase(_.replace(errorInputName, "updateDetails.", ""));
                  this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
                } else {
                  this.setMessage('v-notify-error', "Sorry!", "Errors in the form!");
                }
              }
            }
          })
          .catch(() => {
            let firstErrorInput = $('input.error:first')[0];
            if (firstErrorInput) {
              firstErrorInput.scrollIntoView({behavior: 'instant'});
              firstErrorInput.focus();
            }
            if (this.errors.items && this.errors.items.length > 1) {
              this.setMessage('v-notify-error', "Oops!", "Please correct the " + this.errors.items.length + " errors on the form.");
            } else {
              let errorInputName = firstErrorInput.dataset.vvAs || firstErrorInput.name || 'form';
              if (errorInputName && errorInputName !== '') {
                errorInputName = _.upperCase(_.replace(errorInputName, "updateDetails.", ""));
                this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
              } else {
                this.setMessage('v-notify-error', "Sorry!", "Errors in the form!");
              }
            }
          });
    },
    async changePassword() {
      await Auth.currentAuthenticatedUser()
          .then(user => {
            return Auth.changePassword(user, this.passwordDetails.oldPassword, this.passwordDetails.newPassword);
          })
          .then(data => {
            if (data === 'SUCCESS') {
              this.setMessage('v-notify-success', "Congratulations!", "Your password was changed successfully!");
            } else {
              this.setMessage('v-notify-error', "Error!", data);
            }
          })
          .catch(e => this.setMessage('v-notify-error', "Error!", e));
    },
    updatePassword: function () {
      this.$validator.validate('passwordDetails.*')
          .then((result) => {
            if (result) {
              this.changePassword();
            } else {
              let firstErrorInput = $('input.error:first')[0];
              if (firstErrorInput) {
                firstErrorInput.scrollIntoView({behavior: 'instant'});
                firstErrorInput.focus();
              }
              if (this.errors.items && this.errors.items.length > 1) {
                this.setMessage('v-notify-error', "Oops!", "Please correct the " + this.errors.items.length + " errors on the form.");
              } else {
                let errorInputName = firstErrorInput.dataset.vvAs || firstErrorInput.name || 'form';
                if (errorInputName && errorInputName !== '') {
                  errorInputName = _.upperCase(_.replace(errorInputName, "passwordDetails.", ""));
                  this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
                } else {
                  this.setMessage('v-notify-error', "Sorry!", "Errors in the form!");
                }
              }
            }
          })
          .catch(() => {
            let firstErrorInput = $('input.error:first')[0];
            if (firstErrorInput) {
              firstErrorInput.scrollIntoView({behavior: 'instant'});
              firstErrorInput.focus();
            }
            if (this.errors.items && this.errors.items.length > 1) {
              this.setMessage('v-notify-error', "Oops!", "Please correct the " + this.errors.items.length + " errors on the form.");
            } else {
              let errorInputName = firstErrorInput.dataset.vvAs || firstErrorInput.name || 'form';
              if (errorInputName && errorInputName !== '') {
                errorInputName = _.upperCase(_.replace(errorInputName, "passwordDetails.", ""));
                this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
              } else {
                this.setMessage('v-notify-error', "Sorry!", "Errors in the form!");
              }
            }
          });
    },
    openVerifyModal: function (e) {
      const attribute = e.target.id === 'emailVerify' ? 'email' : 'phone_number';

      Auth.verifyCurrentUserAttribute(attribute)
          .then(() => {
            $('#verificationCode').attr('name', attribute);
            $("#verificationModal").modal('show');
          }).catch((e) => {
        this.setMessage('v-notify-error', "Error!", e);
      });
    },
    verifyAttribute: function () {
      Auth.verifyCurrentUserAttributeSubmit($('#verificationCode').attr('name'), this.verificationCode)
          .then(() => {
            this.setMessage('v-notify-success', "Awesome!", "The verification was successful!");

            $('#verificationCode').removeAttr('name');
            $("#verificationModal").modal('hide');

            this.refreshUserAttributes();
          }).catch(e => {
        this.setMessage('v-notify-error', "Error!", e);
      });
    },
    async refreshUserAttributes() {
      const user = await Auth.currentAuthenticatedUser();
      const attributes = await Auth.userAttributes(user);
      _.forEach(attributes, (attribute) => {
        user.attributes[attribute.Name] = attribute.Value;
      });
      this.UPDATE_COGNITO_USER(user);


      if (!this.getLoggedInUser.emailVerified || user.attributes.email_verified !== this.getLoggedInUser.emailVerified.toString()) {
        try {
          this.updateDetails.emailVerified = user.attributes.email_verified;
          await API.graphql(graphqlOperation(mutations.updateUser, {
            input: {
              id: this.getLoggedInUser.id,
              emailVerified: user.attributes.email_verified
            }
          }));
        } catch (e) {
          this.setMessage('v-notify-error', "Error!", e);
        }
      }
      if (!this.getLoggedInUser.phoneVerified || user.attributes.phone_number_verified !== this.getLoggedInUser.phoneVerified.toString()) {
        try {
          this.updateDetails.phoneVerified = user.attributes.phone_number_verified;
          await API.graphql(graphqlOperation(mutations.updateUser, {
            input: {
              id: this.getLoggedInUser.id,
              phoneVerified: user.attributes.phone_number_verified
            }
          }));
        } catch (e) {
          this.setMessage('v-notify-error', "Error!", e);
        }
      }
    },
    generateUniqueLink: async function () {
      this.updateDetails.website = ' ';

      API.get("SalesVoltPrivate", "/formUrl", {
        headers: {
          Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      }).then(response => {
        if (response && response.url_short && response.url_short !== '') {
          this.setMessage('v-notify-success', "Alert!", 'Make sure to click \'Update Profile\' to save your new link!', 5000);
          this.updateDetails.website = response.url_short;
        } else {
          this.updateDetails.website = '';
          this.setMessage('v-notify-error', "Error!", 'Unable to get your unique link at this time');
        }
      }).catch(e => {
        this.updateDetails.website = '';
        this.setMessage('v-notify-error', "Error!", e);
      });
    }
  }
}
</script>

<style scoped>

</style>
